import React from 'react';
import { Formik, Form, FormikConfig } from 'formik';

import {
    container,
    button,
    globalError,
    requiredText,
    loading,
} from './client-data-form.module.scss';
import { IClientRegisterMutation, IClientUpdateMutation } from '../../models/client.model';
import useTranslations from '../../hooks/use-translations';
import { useClient } from '../../hooks/use-client';
import { useModal } from '../../hooks/use-modal';
import useRtkQueryFormError from '../../hooks/use-rtk-query-form-error';
import { useSharedFile } from '../../hooks/use-shared-file';
import { ESharedFileRelation } from '../../models/shared-file.model';
import {
    clientConsentsData,
    getClientDataFields,
    getClientDataInitialValues,
    getClientDataValidationSchema,
    IClientDataValues,
    TClientDataFormType,
} from '../../formik/client-data.form';

import FieldGenerator from '../molecules/field-generator';
import Button from '../atoms/button';
import Consents from '../molecules/consents';

interface IClientDataFormProps {
    className?: string;
    type: TClientDataFormType;
}

const ClientDataForm: React.FC<IClientDataFormProps> = ({ className = '', type }) => {
    const t = useTranslations('ClientDataForm');
    const tConsents = useTranslations('Consents');
    const tInputAddress = useTranslations('InputAddress');
    const { addModal } = useModal();
    const client = useClient();
    const errors = type === 'register' ? client.register.errors : client.update.errors;
    const { formikRef, globalErrorMessage } = useRtkQueryFormError(errors);
    const clientRegulations = useSharedFile(ESharedFileRelation.clientRegulations);
    const privacyPolicy = useSharedFile(ESharedFileRelation.privacyPolicy);

    const isLoading = client.register.isLoading || client.update.isLoading;

    const handleSubmit: FormikConfig<IClientDataValues>['onSubmit'] = async (values) => {
        if (type === 'register') {
            const mutation: IClientRegisterMutation = {
                ...values,
                password: values.password as string,
            };
            client.register.fetch(mutation);
        }
        if (type === 'edit') {
            const mutation: IClientUpdateMutation = {
                ...values,
            };
            try {
                await client.update.fetch(mutation).unwrap();
                addModal({
                    modalKey: 'common-modal',
                    modalProps: {
                        title: t.updateSuccess.title,
                        confirmText: t.updateSuccess.confirm,
                        children: t.updateSuccess.content,
                        actions: ['confirm'],
                    },
                });
            } catch {}
        }
    };

    return (
        <Formik
            innerRef={formikRef}
            onSubmit={handleSubmit}
            initialValues={getClientDataInitialValues(type, client.data)}
            validationSchema={getClientDataValidationSchema(t, tConsents, tInputAddress, type)}
            enableReinitialize={true}
        >
            {() => (
                <Form className={`${container} ${className} ${isLoading ? loading : ''}`}>
                    {getClientDataFields(t, type).map((field) => {
                        return (
                            <FieldGenerator
                                key={`client-data-field-${field.name}-${field.type}`}
                                field={field}
                            />
                        );
                    })}
                    {type === 'register' && (
                        <>
                            <p className={requiredText}>{t.required}</p>
                            <Consents
                                consents={clientConsentsData(
                                    tConsents.consentRodo(privacyPolicy?.pathname || ''),
                                    tConsents.consentRegulationsAndPrivacyPolicy(
                                        privacyPolicy?.pathname || '',
                                        clientRegulations?.pathname || ''
                                    )
                                )}
                                showConsentsDisclaimer={true}
                            />
                        </>
                    )}
                    {globalErrorMessage && <p className={globalError}>{globalErrorMessage}</p>}
                    <Button className={button} type="submit" isLoading={isLoading}>
                        {t[type]}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default ClientDataForm;
