import React from 'react';

import { container, grid, sectionBox, radiusBox, form } from './client-settings.module.scss';
import { ISection } from '../../models/section.model';
import { useClientPermission } from '../../hooks/use-client-permission';

import Section from '../hoc/section';
import RadiusBox from '../hoc/radius-box';
import ClientDataForm from '../organisms/client-data-form';
import NoPermissionInfo from '../organisms/no-permission-info';

export interface IClientSettingsProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

const ClientSettings: React.FC<IClientSettingsProps> = ({ className = '', section }) => {
    const { style, css, sectionId } = section;
    const canSettings = useClientPermission(['can-settings']);

    if (!canSettings) {
        return <NoPermissionInfo isFullHeight={true} reason="role" />;
    }

    return (
        <Section
            className={`${sectionBox} ${className}`}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            <div className={container}>
                <RadiusBox className={radiusBox}>
                    <ClientDataForm className={form} type="edit" />
                </RadiusBox>
            </div>
        </Section>
    );
};

export default ClientSettings;
