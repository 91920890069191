import * as Yup from 'yup';
import { getMediaByRelation } from '@alterpage/gatsby-plugin-image';

import { relations } from '../config/relations';
import IFormField from '../models/form-field.model';
import { IConsent } from '../models/consent';
import { ILocationAddress } from '../models/address.model';
import { IClient } from '../models/client.model';
import { IFileBase64 } from '../models/file-base-64.model';
import { getConsentsInitialValues, getConsentsValidationSchema } from './consents.form';
import useTranslations from '../hooks/use-translations';
import { getAddressInitialValues, getAddressValidationSchema } from './address.form';

export type TClientDataFormType = 'edit' | 'register';

export interface IClientDataValues {
    email: string;
    password?: string;
    taxNumber: string;
    companyName: string;
    logo?: IFileBase64 | '';
    address: ILocationAddress;
}

export const clientConsents: IConsent[] = [
    {
        name: 'consentMarketing',
        required: false,
    },
    {
        name: 'consentRodo',
        required: true,
    },
    {
        name: 'consentRegulationsAndPrivacyPolicy',
        required: true,
    },
];

export const clientConsentsData: (
    rodoContent: string,
    regulationsAndPrivacyContent: string
) => IConsent[] = (rodoContent: string, regulationsAndPrivacyContent: string) => {
    return [
        {
            name: 'consentMarketing',
            required: false,
        },
        {
            name: 'consentRodo',
            content: rodoContent,
            required: true,
        },
        {
            name: 'consentRegulationsAndPrivacyPolicy',
            content: regulationsAndPrivacyContent,
            required: true,
        },
    ];
};

export function getClientDataInitialValues(
    formType: TClientDataFormType,
    client?: IClient | null
): IClientDataValues {
    if (formType === 'register' || !client) {
        return {
            email: '',
            password: '',
            taxNumber: '',
            companyName: '',
            ...getAddressInitialValues(),
            ...getConsentsInitialValues(clientConsents),
        };
    }
    const logoMedia = getMediaByRelation({ media: client.media, relation: relations.mainImage });
    return {
        email: client.email,
        taxNumber: client.taxNumber,
        companyName: client.name,
        logo: logoMedia
            ? {
                  name: logoMedia.name,
                  url: logoMedia.url,
                  mimeType: logoMedia.type,
                  content: null,
              }
            : '',
        ...getAddressInitialValues({
            initialAddress: client.address,
        }),
    };
}

export function getClientDataValidationSchema(
    t: ReturnType<typeof useTranslations<'ClientDataForm'>>,
    tConsents: ReturnType<typeof useTranslations<'Consents'>>,
    tInputAddress: ReturnType<typeof useTranslations<'InputAddress'>>,
    formType: TClientDataFormType
) {
    return Yup.object({
        email: Yup.string().email(t.email.error.invalid).required(t.email.error.required),
        ...(formType === 'register'
            ? {
                  password: Yup.string()
                      .min(8, t.password.error.min)
                      .max(32, t.password.error.max)
                      .required(t.password.error.required),
              }
            : {}),
        taxNumber: Yup.string().required(t.taxNumber.error.required),
        companyName: Yup.string().required(t.companyName.error.required),
        ...getAddressValidationSchema(tInputAddress),
        ...(formType === 'register' ? getConsentsValidationSchema(tConsents, clientConsents) : {}),
    });
}

export function getClientDataFields(
    t: ReturnType<typeof useTranslations<'ClientDataForm'>>,
    formType: TClientDataFormType
): IFormField[] {
    const fields: IFormField[] = [
        {
            name: 'email',
            type: 'email',
            placeholder: t.email.placeholder,
            hint: t.email.hint,
            isRequired: true,
            disabled: formType === 'edit',
        },
        {
            name: 'password',
            type: 'password',
            placeholder: t.password.placeholder,
            isRequired: true,
        },
        {
            name: 'taxNumber',
            type: 'text',
            placeholder: t.taxNumber.placeholder,
            isRequired: true,
            disabled: formType === 'edit',
            hint: formType === 'edit' ? t.taxNumber.hint : '',
        },
        {
            name: 'companyName',
            type: 'text',
            placeholder: t.companyName.placeholder,
            isRequired: true,
        },
        {
            name: 'logo',
            type: 'file',
            placeholder: t.logo.placeholder,
            onlyImages: true,
            hint: formType === 'edit' ? t.logo.hint : '',
        },
        {
            name: '',
            type: 'input-address',
            includeLocation: false,
        },
    ];

    if (formType === 'register') {
        return fields.filter((field) => field.name !== 'logo');
    }
    return fields.filter((field) => field.name !== 'password' && field.type !== 'consents');
}
